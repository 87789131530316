<!--  -->
<template>
    <div class="box">
        <div class="form">
            <div class="logo">
                <img src="~@/assets/images/distributionIndex/logo.png" alt="">
                <p>您好,</p>
                <p>欢迎使用通信网服中心</p>
            </div>
            <div class="label">
                <img class="mobileicon"  src="~@/assets/images/distributionIndex/mobile.png" alt="">
                <span>手机号</span>
            </div>
            <div>
                <van-field v-model="mobile" maxlength="11"  type="number"  placeholder="请输入手机号" />
            </div>
            <div class="label">
                <img class="passwordicon" src="~@/assets/images/distributionIndex/password.png" alt="">
                <span>验证码</span>
            </div>
            <div class="codes">
                <van-field v-model="code" center   type="number" maxlength="6" placeholder="请输入短信验证码">
                    <template #button>
                        <van-button size="small" type="info" round :disabled="disabled"  @click="getcode()">{{text}}</van-button>
                    </template>
                </van-field>
            </div>
            <div class="btn"><van-button round type="info" @click="login()">登录</van-button> </div>
            
        </div>
    </div>
</template>

<script>
import { send,mobileLogin } from '@/untils/distubritionIndex.js'

export default {
    name: '',
    data() {
        return {
            mobile: '',
            code: '',
            text:'获取验证',
            disabled:false,
            info:{}
        }

    },
    mounted() {
        // console.log(JSON.parse(window.localStorage.getItem('UserInfo')));
        if(JSON.parse(window.localStorage.getItem('UserInfo')) != null){
            console.log(23);
            this.$router.push({path:'/distributionIndex',query:{mobile:JSON.parse(window.localStorage.getItem('UserInfo')).mobile}})//类似get请求  数据会暴露在地址栏  (携带数据跳转)
        }        

     },
    methods:{
        login(){
            if(this.mobile.trim().length != 11){
                return this.$toast('请输入完整的手机号')
            }
             if(this.code.trim().length != 6){
                return this.$toast('请输入完整的验证码')
            }
            let data = {
                mobile:this.mobile,
                code:this.code
            }
            mobileLogin({data}).then(res=>{
                if(res.data.code == 200 ){
                    this.$toast(res.data.msg)
                    this.getinfo()
                    setTimeout(()=>{
                         this.$router.push({path:'/distributionIndex',query:{mobile:this.mobile}})//类似get请求  数据会暴露在地址栏  (携带数据跳转)
                    },500)
                
                }else {
                    this.$toast(res.data.msg)
                }

            })
        },
    async getinfo() {
      let data = {
        mobile: this.mobile,
      };
      await this.getUserInfo({ data }).then((res) => {
        this.info = res.data.data.data;
        sessionStorage.setItem("UserInfo", JSON.stringify(this.info));
        window.localStorage.setItem('UserInfo', JSON.stringify(this.info))
      });
    },
        getcode(){
            if(this.mobile.trim().length != 11){
                return this.$toast('请输入完整的手机号')
            }
            this.disabled = true
            this.text = 60
            let data = {
                mobile:this.mobile
            }
            send({data}).then(res=>{
                console.log(res);
                if(res.data.code ==200){
                      this.$toast(res.data.msg)
                }else {
                        this.$toast(res.data.msg)
                }
              
            })
            let timer = setInterval(()=>{
                     this.text-- 
                    if(this.text == 0){
                        this.text = "获取验证"
                        clearInterval(timer)
                         this.disabled = false
                    }
                 },1000)
             

        }
    }
};
</script>
<style scoped lang="less">
.box {
    min-height: 100vh;
    background: url('~@/assets/images/distributionIndex/bg2.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 99px;
    box-sizing: border-box;
    background-attachment: fixed;
    background-size: cover;
    .form {

        /deep/ .van-cell {
           background-color:#fff0 !important;
            border-bottom: 1px solid #ACC1C7;
        }

        width: 280px;
        margin: 0 auto;

        .logo {
            margin-bottom: 43px;

            img {
                width: 74px;
                height: 64px;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
                color: #333333;
                margin-bottom: 5px;
                font-weight: 500;
            }
        }

        .label {
            width: 100%;
            height: 23px;
            display: flex;
            align-items: center;
            // margin-bottom: 15px;
            margin-top: 15px;

            .mobileicon {
                width: 11.25px;
                height: 17.5px;
                margin-right: 10px;
            }

            .passwordicon {
                width: 15.09px;
                height: 17.04px;
                margin-right: 10px;
            }
        }

        .codes {
            /deep/ .van-button{
                width: 80px;
            }
        }
    .btn {
        margin-top: 53px;
        /deep/ .van-button {
            width: 100%;
        }
    }

    }
}
</style>